import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Axioms`}</h1>
    <p>{`These are some axioms that relate to software development that I have found useful and instructive over the years.`}</p>
    <ul>
      <li parentName="ul">{`A Good Function - is very small and does very little (ideally one thing).`}</li>
      <li parentName="ul">{`"You will never know less than you know right now." - Practical Object-Oriented Design (book)`}</li>
      <li parentName="ul">{`"Make the change easy (warning: this may be hard), then make the easy change." -Kent Beck`}</li>
      <li parentName="ul">{`"Depending on an abstraction is always safer than depending on a concretion because by its very nature, the abstraction is more stable." - Practical Object-Oriented Design (book)`}</li>
      <li parentName="ul">{`"Give me six hours to chop down a tree and I will spend the first four sharpening the axe." - Abraham Lincoln`}</li>
      <li parentName="ul">{`"Slow is smooth smooth is fast" - Navy Seals`}</li>
      <li parentName="ul">{`Discovery: build the right product.`}</li>
      <li parentName="ul">{`Delivery: built the product right.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      